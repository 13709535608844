<script>
  import Base from '@factry/base'
  import EquipmentManager from '@factry/equipment-manager'
  import MaterialManager from '@factry/material-manager'
  import OrderManager from '@factry/order-manager'
  import WarehouseManager from '@factry/warehouse-manager'
  import Queue from '@factry/message-queue-manager'
  import DowntimeManager from '@factry/downtime-manager'
  import TestingManager from '@rogerroger/plc-testing-manager'
</script>

<Base
  options={[
    EquipmentManager, MaterialManager, WarehouseManager, Queue, OrderManager,
    DowntimeManager, TestingManager,
  ]}
/>
