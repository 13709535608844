import '@fortawesome/fontawesome-free/css/all.min.css'
import '@factry/bulma'
import * as Sentry from '@factry/sentry'
import { initialize } from '@factry/base'
import App from './App.svelte'
import background from './assets/rogerroger-bg.jpg'
import logo from './assets/rogerroger-logo.svg'

Sentry.init({
  version: import.meta.env.VITE_VERSION,
  dsn: import.meta.env.VITE_SENTRY_DSN,
  environment: import.meta.env.MODE,
})

initialize({
  background,
  defaultLocale: 'nl',
  availableLocales: ['nl', 'en', 'pl', 'fr'],
  basePath: import.meta.env.VITE_BASE_PATH,
  logo,
  logoAlt: 'Roger & Roger logo',
  test: import.meta.env.VITE_TEST,
  versions: import.meta.env.VITE_VERSIONS,
})

const app = new App({
  target: document.body,
})

export default app
